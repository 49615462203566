/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import routes from 'routes';

import MenuItem from './lib/MenuItem';

import { ReactComponent as AnalyticsIcon } from 'icons/analytics.svg';
import { ReactComponent as CreatorsIcon } from 'icons/creators.svg';
import { ReactComponent as InvoicesIcon } from 'icons/invoices.svg';
import { ReactComponent as InventoryIcon } from 'icons/inventory.svg';
import { ReactComponent as BalanceIcon } from 'icons/balance.svg';
import { ReactComponent as ResourcesIcon } from 'icons/resources.svg';
import { ReactComponent as SalesIcon } from 'icons/sales.svg';
import { ReactComponent as ScheduleIcon } from 'icons/schedule.svg';
import { ReactComponent as SponsorsIcon } from 'icons/sponsors.svg';
import { ReactComponent as SponsorshipsIcon } from 'icons/staff_sponsorships.svg';
import { ReactComponent as StatementsIcon } from 'icons/statements.svg';

const baseCSS = {
  fill: 'inherit',
  width: '22px',
  height: '22px',
  position: 'relative',
  top: '-1px',
};

const Navigation = ({ 
  account, 
  isPhone, 
}) => {

  let schedule;
  const isCreator = account?.data?.isCreator;
  const isReadOnlyCreator = account?.data?.isReadOnlyCreator
  const isSponsor = account?.data?.isSponsor;
  const isStaff = account?.data?.isStaff;

  if (account && (isCreator || isStaff)) {
    schedule = (
      <MenuItem
        key={routes.Schedule.Index.path}
        to={routes.Schedule.Index.path}
        icon={<ScheduleIcon css={baseCSS} />}
        text={routes.Schedule.Index.title}
      />
    );
  }

  let users;
  if (isStaff) {
    users = (
      <MenuItem
        key={routes.Creators.Index.path}
        to={routes.Creators.Index.path}
        icon={<CreatorsIcon css={baseCSS} />}
        text={routes.Creators.Index.title}
      />
    );
  }

  let sponsors;
  if (isStaff || (isCreator && !isReadOnlyCreator)) {
    sponsors = (
      <MenuItem
        key={routes.Sponsors.Index.path}
        to={routes.Sponsors.Index.path}
        icon={<SponsorsIcon css={baseCSS} />}
        text={routes.Sponsors.Index.title}
      />
    );
  }

  let statements;
  if (account && (isStaff || isCreator)) {
    statements = (
      <MenuItem
        key={routes.Statements.Index.path}
        to={routes.Statements.Index.path}
        icon={<StatementsIcon css={baseCSS} />}
        text={routes.Statements.Index.title}
      />
    );
  }

  let ledger;
  if (account && (isStaff || (isCreator && process.env.REACT_APP_SHOW_LEDGER_TO_CREATORS === "true"))) {
    ledger = (
      <MenuItem
        key={routes.Ledger.Index.path}
        to={routes.Ledger.Index.path}
        icon={<BalanceIcon css={baseCSS} />}
        text={routes.Ledger.Index.title}
      />
    );
  }

  let sales;
  if (isStaff) {
    sales = (
      <MenuItem
        key={routes.Sales.Index.path}
        to={isStaff ? routes.Sales.Index.path + '#drafts' : routes.Sales.Index.path}
        icon={<SalesIcon css={baseCSS} />}
        text={routes.Sales.Index.title}
      />
    );
  }

  let sponsorships;
  if (isStaff || isSponsor) {
    sponsorships = (
      <MenuItem
        key={routes.Sponsorships.Index.path}
        to={routes.Sponsorships.Index.path}
        icon={isStaff ? <SponsorshipsIcon css={baseCSS}/> : <SalesIcon css={baseCSS} />}
        text={routes.Sponsorships.Index.title}
      />
    );
  }

  let invoices;
  if (isStaff || isSponsor) {
    invoices = (
      <MenuItem
        key={routes.Invoices.Index.path}
        to={isStaff ? routes.Invoices.Index.path + '?status=unpaid' : routes.Invoices.Index.path}
        icon={<InvoicesIcon css={baseCSS} />}
        text={routes.Invoices.Index.title}
      />
    );
  }

  let ios;
  if (isSponsor) {
    ios = (
      <MenuItem
        key={routes.InsertionOrder.Index.path}
        to={routes.InsertionOrder.Index.path + '#unsigned'}
        icon={<StatementsIcon css={baseCSS} />}
        text={routes.InsertionOrder.Index.title}
      />
    );
  }

  let inventory;
  if (isStaff || isSponsor) {
    inventory = (
      <MenuItem
        key={routes.Inventory.Index.path}
        to={routes.Inventory.Index.path + '#youtube'}
        icon={<InventoryIcon css={baseCSS} />}
        text={routes.Inventory.Index.title}
      />
    );
  }

  let analytics;
  if (account && (isCreator || isStaff || isSponsor)) {
    analytics = (
      <MenuItem
        key={routes.Analytics.Index.path}
        to={
          isStaff || isCreator
            ? routes.Analytics.Index.path + '#nebula' 
            : routes.Analytics.Index.path + '#youtube'
        }
        icon={<AnalyticsIcon css={baseCSS} />}
        text={routes.Analytics.Index.title}
      />
    );
  }

  let resources;
  if (account && (isStaff || (isCreator && !isReadOnlyCreator))) {
    resources = (
      <MenuItem
        key={routes.Resources.Index.path}
        to={routes.Resources.Index.path + '#free-stuff'}
        icon={<ResourcesIcon css={baseCSS} />}
        text={routes.Resources.Index.title}
      />
    );
  }

  return (
    <nav
      style={{
        marginTop: isPhone ? 0 : 25,
      }}
    >
      {schedule}
      {users}
      {sponsors}
      {ledger}
      {statements}
      {sales}
      {sponsorships}
      {ios}
      {invoices}
      {inventory}
      {analytics}
      {resources}
    </nav>
  );
};

Navigation.propTypes = {};

export default Navigation;
