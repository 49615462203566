import { 
    createContext, 
    useCallback,
    useContext, 
    useState 
  } from 'react';
  
  export const AlertContext = createContext();
  
  function AlertProvider ({ 
    children, 
  }) {
  
    const [type, setType] = useState(null);
    const [message, setMessage] = useState('');
    const [persist, setPersist] = useState(false);

    const clearAlert = useCallback(() => {
      setType(null);
      setMessage('');
      setPersist(false);
    }, [
      setType,
      setMessage,
      setPersist,
    ]);

    const alertFailure = useCallback((message, persist = false) => {
      setType('error');
      setMessage(message);
      setPersist(persist);
    }, [
      setType,
      setMessage,
      setPersist,
    ]);

    const alertSuccess = useCallback((message, persist = false) => {
      setType('success');
      setMessage(message);
      setPersist(persist);
    }, [
      setType,
      setMessage,
      setPersist,
    ]);
      
    return (
      <AlertContext.Provider 
        value={{
          alert: {
            type,
            message,
            persist,
          },
          clearAlert,
          alertFailure,
          alertSuccess,
        }}
      >
        { children }
      </AlertContext.Provider >
    );
  }
  
  export const withAlert = WrappedComponent => props => {
    const auth = useAlert();
    return <WrappedComponent {...props} {...auth} />;
  };
  
  export const useAlert = () => useContext(AlertContext);
    
  export default AlertProvider;
  