/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

const Button = ({ type, style, onClick, isActive, children }) => {
  let css = {
    appearance: 'button',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid transparent',
    borderColor: '#d9d9d9',
    borderRadius: '4px',
    color: '#80898E',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '13px',
    fontWeight: '400',
    height: '32px',
    lineHeight: '1.5',
    outline: '0',
    padding: '0px 30px',
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    touchAction: 'manipulation',
    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#9bd7f2',
      backgroundColor: '#fff',
      borderColor: '#9bd7f2',
    },
    '&:focus': {
      color: '#9bd7f2',
      backgroundColor: '#fff',
      borderColor: '#9bd7f2',
    },

  };
  switch (type) {
    case 'primary':
      css = Object.assign({}, css, {
        color: '#fff',
        backgroundColor: '#6fbce6',
        borderColor: '#6fbce6',
        '&:hover': Object.assign({}, css['&:hover'], {
          color: '#fff',
          backgroundColor: '#9bd7f2',
          borderColor: '#9bd7f2',
        }),
        '&:focus': Object.assign({}, css['&:focus'], {
          color: '#fff',
          backgroundColor: '#9bd7f2',
          borderColor: '#9bd7f2',
        }),
      });
      break;
    case 'dark':
      css = Object.assign({}, css, {
        color: '#6FBCE6',
        backgroundColor: '#00141E',
        borderColor: '#6FBCE6',
        '&:hover': Object.assign({}, css['&:hover'], {
          color: '#6FBCE6',
          backgroundColor: '#00141E',
          borderColor: '#9bd7f2',
        }),
        '&:focus': Object.assign({}, css['&:focus'], {
          color: '#6FBCE6',
          backgroundColor: '#00141E',
          borderColor: '#6FBCE6',
        }),
      });
      break;
    case 'nebula-secondary':
      css = Object.assign({}, css, {
        color: isActive ? '#fff' : '#2CADFE',
        backgroundColor: isActive ? '#2CADFE' : '#fff',
        borderColor: isActive ? '#2CADFE' : '#888F991A',
        'path': {
          fill: isActive ? '#fff' : '#2CADFE',
          transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
        },
        '&:hover': Object.assign({}, css['&:hover'], {
          color: '#fff',
          backgroundColor: '#2CADFE',
          borderColor: '#2CADFE',
          'path': {
            fill: '#fff',
          },
        }),
      });
      break;
    case 'nebula-blue':
      css = Object.assign({}, css, {
        color: '#fff',
        backgroundColor: '#2CADFE',
        borderColor: '#2CADFE',
        '&:hover': Object.assign({}, css['&:hover'], {
          color: '#fff',
          backgroundColor: '#2CADFE',
          borderColor: '#2CADFE',
        }),
        '&:focus': Object.assign({}, css['&:focus'], {
          color: '#fff',
          backgroundColor: '#2CADFE',
          borderColor: '#2CADFE',
        }),
      });
      break;
    case 'nebula-secondary-blue':
      css = Object.assign({}, css, {
        color: '#2CADFE',
        backgroundColor: '#fff',
        borderColor: '#2CADFE',
        'path': {
          fill: '#2CADFE',
          transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
        },
        '&:hover': Object.assign({}, css['&:hover'], {
          color: '#2CADFE',
          backgroundColor: '#fff',
          borderColor: '#2CADFE',
          'path': {
            fill: '#2CADFE',
          },
        }),
      });
      break;
    case 'nebula-red':
      css = Object.assign({}, css, {
        color: '#fff',
        backgroundColor: '#cd2832',
        borderColor: '#cd2832',
        '&:hover': Object.assign({}, css['&:hover'], {
          color: '#fff',
          backgroundColor: '#cd2832',
          borderColor: '#cd2832',
        }),
        '&:focus': Object.assign({}, css['&:focus'], {
          color: '#fff',
          backgroundColor: '#cd2832',
          borderColor: '#cd2832',
        }),
      });
      break;
    case 'nebula-secondary-red':
      css = Object.assign({}, css, {
        color: '#cd2832',
        backgroundColor: '#fff',
        borderColor: '#cd2832',
        'path': {
          fill: '#cd2832',
          transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
        },
        '&:hover': Object.assign({}, css['&:hover'], {
          color: '#cd2832',
          backgroundColor: '#fff',
          borderColor: '#cd2832',
          'path': {
            fill: '#cd2832',
          },
        }),
      });
      break;
    default:
      break;
  }
  return (
    <button
      css={Object.assign({}, css, style)}
      onClick={onClick ? onClick : () => null}
    >
      { children }
    </button>
  );
};

export default Button;
